
















import Component, { mixins } from 'vue-class-component';
// import { Prop, Vue } from 'vue-property-decorator';
import OrganisationProfileView from '@/modules/organisation/pages/OrganisationProfileView.vue';
import { tenantModule } from '../../../store/modules/tenant/tenant.module';
import { mapGetters, mapState } from 'vuex';
import PaymentsDto from '../../../app/entities/subscription/IPaymentDto';
import { ITenantDto } from '@/app/entities';
import ApiService from '@/services/api/accommodation.service';
import { Ref } from 'vue-property-decorator';
import service from '../../../services/app-http-client';
import OrganisationPaymentModal from '@/modules/organisation/components/Pricing.vue';
import UpgradeButton from '@/modules/marketing/pricing/UpgradeButton.vue';
import SubscribeButton from '@/modules/marketing/pricing/SubscribeButton.vue';
import { IBillingDto } from '../../../app/entities';
import SchemaMixin from '@/shared/mixins/schema.mixins';
import userModule from '@/store/modules/user/user.module';
import VueSimpleRangeSlider from 'vue-simple-range-slider';
import 'vue-simple-range-slider/dist/vueSimpleRangeSlider.css';
import dayjs from 'dayjs';
import Routes from '@/shared/constants/routes';

interface FormulateForm {
  formSubmitted(): boolean;
}

@Component({
  name: 'subscription',
  components: {
    OrganisationProfileView,
    OrganisationPaymentModal,
    UpgradeButton,
    SubscribeButton,
    VueSimpleRangeSlider,
  },
  layout: 'AppMain',
  computed: {
    ...mapState('tenant', ['payments', 'tenant', 'billing']),
    ...mapGetters('tenant', ['billing']),
  },
})
export default class Subscription extends mixins(SchemaMixin) {
  @Ref('bill') readonly bill: FormulateForm;

  ORGANISATION_BILLING = Routes.ORGANISATION_BILLING;

  payments!: PaymentsDto[];
  tenant: ITenantDto;
  billing!: IBillingDto;
  trial = tenantModule.tenant.subscription.plan.name === 'Trial';
  popupActive = false;
  userRole = userModule.member[0].role.role;
  loading = true;
  showPaymentModal = false;
  billingFormComplete = false;
  sliderValue = 50;
  sliderRandValueString = 110;
  randValue: number;

  created() {
    if (this.billing?.billingCompany && this.billing?.billingEmail && this.billing?.vatNo !== '') {
      return (this.billingFormComplete = true);
    }
  }

  goToBilling(path: any) {
    return this.$router.push(path);
  }

  sliderRandConvertor() {
    const newSliderValue = this.sliderValue * 2.2;
    const sliderRandValueString = newSliderValue.toFixed(0);
    const sliderRandValueInt = parseInt(sliderRandValueString);
    this.randValue = sliderRandValueInt;
    return sliderRandValueInt;
  }

  expiryDate() {
    return dayjs(this.tenant.subscription.currentPeriodEnd).format('DD MMMM YYYY');
  }

  async handleTopUp(sliderValue: number, randValue: number) {
    const response = await this.$swal({
      icon: 'question',
      title: `Top up ${sliderValue} credits for R${randValue}? `,
      text: `These credits will expire on the same date as your monthly subscription renewal.`,
      position: 'center',
      showConfirmButton: true,
      confirmButtonText: 'Accept',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
    });
    if (response.isConfirmed) {
      this.$router.push({ path: 'checkout', query: { credits: sliderValue.toString(), amount: randValue.toString() } });
    }
  }

  async cancelSubscription() {
    const response = await this.$swal({
      icon: 'warning',
      title: 'Cancel subscription',
      text: 'Are you sure you want to cancel your subscription?',
      position: 'center',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      showConfirmButton: true,
      confirmButtonText: "I'm sure",
    });

    if (response.isConfirmed) {
      try {
        await ApiService.put('billing/cancel');
        await this.$swal({
          icon: 'success',
          title: 'Canceled subscription',
          text: `Your current subscription has been cancelled and will not renew. Any outstanding credits will expire on ${dayjs(
            this.tenant.subscription.currentPeriodEnd,
          ).format('DD MMMM YYYY')}`,
          position: 'center',
          showConfirmButton: true,
          confirmButtonText: 'Ok',
        });
      } catch (error: any) {
        console.log(error);
      }
    }
  }

  async upgradeSubscription() {
    try {
      const res = await service.put('billing/update/700047d6-e5a7-4de8-8b76-d172e8b16a93');
    } catch (error: any) {
      console.log(error);
    }
  }
}
