import service from '@/services/app-http-client';
import { AccommodationDto } from '../../app/entities/tenant/accommodation.dto';

class ApiService {
  findOne(path: string): Promise<AccommodationDto> {
    return new Promise((resolve, reject) => {
      service
        .get(path)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  put(path: string, payload: any = null) {
    return new Promise((resolve, reject) => {
      service
        .put(path, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export default new ApiService();
